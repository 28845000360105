import { useEffect, useState, useRef, useContext } from "react";
import { getQuickSheets } from "../API/quickSheets";
import QuickSheetsListView from "../components/QuickSheetsListView";
import QuickSheetsGridView from "../components/QuickSheetsGridView";
import { ProductsModal, ProductsModalButton } from "../components/ProductsModal";
import ProvidersModal from "../components/ProvidersModal";
import TerritoriesModal from "../components/TerritoriesModal";
import Tooltip from "../components/Tooltip";
import { AppContext } from "../util/AppContext";
import PreviewModal from "../components/PreviewModal";
import { downloadTemplate } from "../API/documentGenerate";
import ToastAlert from "../components/ToastAlert";
import { getConfigValue } from "../util/getConfigValue";
import { useErrorBoundary } from "react-error-boundary";

function QuickSheetsPage() {
    const [appState, setAppState] = useContext(AppContext);
    const [isListView, setIsListView] = useState(true);
    const [documents, setDocuments] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDocument, setIsLoadingDoucment] = useState(false);
    const [hasNoResults, setHasNoResults] = useState(false);

    const [showProductsModal, setShowProductsModal] = useState(false);
    const [showProvidersModal, setShowProvidersModal] = useState(false);
    const [showTerritoriesModal, setShowTerritoriesModal] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);

    const [markets] = useState(JSON.parse(sessionStorage.getItem('marketsForUser')) || []);
    const [territories] = useState(getUserTerritories(JSON.parse(sessionStorage.getItem('marketsForUser'))));

    const [productImageSource, setProductImageSource] = useState();
    const [showToast, setShowToast] = useState(false);
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');

    const [currentProduct, setCurrentProduct] = useState(null);
    const [currentProvider, setCurrentProvider] = useState(null);
    const [currentTerritory, setCurrentTerritory] = useState(null);

    const [showProviderTooltip, setShowProviderTooltip] = useState(false);
    const providerTooltipTarget = useRef(null);

    const [showTerritoryTooltip, setShowTerritoryTooltip] = useState(false);
    const territoryTooltipTarget = useRef(null);

    const [pdfUrl, setPdfUrl] = useState(null);
    const [pdfFileName, setPdfFileName] = useState('');
    const { showBoundary } = useErrorBoundary();

    const [canResetProvider, setCanResetProvider] = useState(false);
    const [canResetTerritory, setCanResetTerritory] = useState(false);

    useEffect(() => {
        if (appState?.currentProduct === "") {
            setCurrentProduct(appState?.productDetails[0]);
            setProductImageSource(appState?.productDetails[0]?.imagePath);
            setAppState({ ...appState, currentProduct: appState?.productDetails[0] });
        } else {
            setCurrentProduct(appState?.currentProduct);
            setProductImageSource(appState?.currentProduct?.imagePath);
        }
    }, [appState, setAppState])

    useEffect(() => {
        if (currentProduct) {
            loadQuickSheets(currentProduct.productId || currentProduct.clientProductId, true, false, currentTerritory?.id || null, currentProvider?.id || null);
        }
    }, [currentProduct, currentTerritory, currentProvider]);

    function getUserTerritories(markets) {
        const territories = [];

        if (!markets || markets.length === 0) {
            return territories;
        }

        markets.forEach((market) => {
            market.territories.forEach((territory) => {
                territories.push({ ...territory, marketName: market.name });
            })
        });

        return territories;
    }

    function loadQuickSheets(productId, includeActive = true, includeExpired = true, territoryId = null, targetId = null) {
        setIsLoading(true);
        getQuickSheets(productId, includeActive, includeExpired, territoryId, targetId)
            .then((data) => {
                if (data.length === 0 && documents.length === 0) {
                    setHasNoResults(true);
                    setDocuments([]);
                } else {
                    setHasNoResults(false);
                    setDocuments(data.filter((e) => e.productId === currentProduct.clientProductId));
                }
                setIsLoading(false);
            })
            .catch((error) => {
                showBoundary(error);
                setIsLoading(false);
            });
    }

    function onProductClick(product) {
        setCurrentProduct(product);
        setProductImageSource(product.imagePath);
        setShowProductsModal(false);
        setAppState({ ...appState, currentProduct: product });
    }

    function onProviderClick(provider) {
        setCurrentProvider(provider);
        setCanResetProvider(true);
        setShowProvidersModal(false);
    }

    function onTerritoryClick(territory) {
        setCurrentTerritory(territory);
        setCanResetTerritory(true);
        setShowTerritoriesModal(false);
    }

    function onResetProvider() {
        setCanResetProvider(false);
        setCurrentProvider(null);
    }

    function onResetTerritory() {
        setCanResetTerritory(false);
        setCurrentTerritory(null);
    }

    function displayTerritory(territory) {
        if (!territory) {
            return '-';
        }

        if (territory?.name) {
            return territory.name;
        }
    }

    function displayProvider(provider) {
        if (!provider) {
            return '-';
        }
        return currentProvider && currentProvider.displayName;
    }

    function triggerToastAlert(title, message) {
        setToastTitle(title);
        setToastMessage(message);
        setShowToast(true);
    }

    async function downloadDocument(id) {
        if (isLoadingDocument) {
            return;
        }
        try {
            setIsLoadingDoucment(true);
            const response = await downloadTemplate(id);
            setIsLoadingDoucment(false);
            return response;
        } catch (ex) {
            setIsLoadingDoucment(false);
            console.error(ex);
            triggerToastAlert("Error", getConfigValue(appState?.config?.QuickSheets?.Download?.DownloadError, appState)?.Value || null);
        }
    }

    async function onPrintDocument(quickSheet) {
        const response = await downloadDocument(quickSheet.documentId);
        const pdfUrl = URL.createObjectURL(response.blob);
        window.open(pdfUrl, "PRINT");
    }

    async function onDownloadDocument(quickSheet) {
        const response = await downloadDocument(quickSheet.documentId);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(response.blob);
        link.download = response.fileName;
        link.click();
    }

    async function onPreviewDoucment(quickSheet) {
        setShowPreviewModal(true);
        const response = await downloadDocument(quickSheet.documentId);
        setPdfUrl(URL.createObjectURL(response.blob));
        setPdfFileName(response.fileName);
    }

    return (
        <>
            <div className="container-xl">
                <div className="row">
                    <div className="col-12">
                        <h1 className="type-24 mt-4">{getConfigValue(appState?.config?.QuickSheets?.Download?.Title, appState)?.Value || null}</h1>
                    </div>
                </div>
            </div>
            <div className="container-xl mt-3 mt-md-4">
                <div className="row d-flex align-items-md-start mb-5">
                    <div className="col-12 col-md-4 mb-3 mb-md-0">
                        <ProductsModalButton
                            setShowProductsModal={setShowProductsModal}
                            productImageSource={productImageSource}
                            productName={currentProduct?.name}
                        ></ProductsModalButton>
                    </div>
                    <div className="col-12 col-md-4 mb-3 mb-md-0">
                        <div className="text-start d-block w-100">
                            <button onClick={() => setShowProvidersModal(true)} className="border-0 px-0 bg-transparent text-start d-block w-100" data-bs-toggle="modal" data-bs-target="#selectTarget">
                                <div className="border border-dark-subtle card p-2 px-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <p className="my-0 color-grey-d1 type-14">Provider</p>
                                            <p className="my-0 type-14">{displayProvider(currentProvider)}</p>
                                        </div>
                                    </div>
                                </div>
                            </button>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <div className="d-flex align-items-center">
                                    <button onClick={() => setShowProvidersModal(true)} data-bs-toggle="modal" data-bs-target="#selectTarget" aria-hidden="true" tabIndex="-1" className="btn-reset m-0 type-12 color-primary text-decoration-underline text-decoration-hover-none">Select target</button>
                                    {canResetProvider && <button type="button" onClick={onResetProvider} className="btn btn-link btn-reset type-12 ms-4">Reset</button>}
                                </div>
                                <button type="button" className="btn-reset"
                                    id="pd-btn-tooltip-printrestriction-row4">
                                    <span ref={providerTooltipTarget} onMouseOver={() => setShowProviderTooltip(true)} onMouseOut={() => setShowProviderTooltip(false)} className="far fa-circle-question type-16 color-primary"></span>
                                    <Tooltip
                                        text="Some sheets are visible only by specifying a target."
                                        ref={providerTooltipTarget}
                                        showTooltip={showProviderTooltip}
                                        index={1}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-3 mb-md-0">
                        <div className="text-start d-block w-100">
                            <button onClick={() => setShowTerritoriesModal(true)} className="border-0 px-0 bg-transparent text-start d-block w-100" data-bs-toggle="modal" data-bs-target="#selectTerritories">
                                <div className="border border-dark-subtle card p-2 px-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <p className="my-0 color-grey-d1 type-14">Territory</p>
                                            <p className="my-0 type-14">{displayTerritory(currentTerritory)}</p>
                                        </div>
                                    </div>
                                </div>
                            </button>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <div className="d-flex align-items-center">
                                    <button onClick={() => setShowTerritoriesModal(true)} type="button" data-bs-toggle="modal" data-bs-target="#selectTarget" aria-hidden="true" tabIndex="-1" className="btn-reset m-0 type-12 color-primary text-decoration-underline text-decoration-hover-none">Select territory</button>
                                    {canResetTerritory && <button type="button" onClick={onResetTerritory} className="btn btn-link btn-reset type-12 ms-4">Reset</button>}
                                </div>
                                <button type="button" className="btn-reset"
                                    id="pd-btn-tooltip-printrestriction-row4">
                                    <span ref={territoryTooltipTarget} onMouseOver={() => setShowTerritoryTooltip(true)} onMouseOut={() => setShowTerritoryTooltip(false)} className="far fa-circle-question type-16 color-primary"></span>
                                    <Tooltip
                                        text="Some sheets are visible only by specifying a territory."
                                        ref={territoryTooltipTarget}
                                        showTooltip={showTerritoryTooltip}
                                        index={1}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row d-flex align-items-center my-5 mb-lg-3 ">
                    <div className="col-12 col-md-10">
                        <h1 className="type-24 type-bold text-primary ag_message_title py-3">{getConfigValue(appState?.config?.QuickSheets?.Download?.Group?.Title, appState)?.Value || null}</h1>
                    </div>
                    <div className="col-12 col-md-2 mt-2 mt-lg-0 d-flex justify-content-md-end">
                        <div className="d-flex">
                            <button onClick={() => setIsListView(true)} className={`btn btn-outline-primary btn-icon-only d-lg-flex me-2 ${isListView ? 'active' : ''}`}><span className="fa-regular fa-list btn-icon"></span></button>
                            <button onClick={() => setIsListView(false)} className={`btn btn-outline-primary btn-icon-only d-lg-flex ${!isListView ? 'active' : ''}`}><span className="fa-regular fa-grid-2 btn-icon"></span></button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

                        {isListView ? (
                            <QuickSheetsListView
                                documents={documents}
                                onPrintDocument={onPrintDocument}
                                onDownloadDocument={onDownloadDocument}
                                isLoading={isLoading}
                                hasNoResults={hasNoResults}
                                onPreviewDoucment={onPreviewDoucment}
                            />
                        ) : (
                            <QuickSheetsGridView
                                documents={documents}
                                onPrintDocument={onPrintDocument}
                                onDownloadDocument={onDownloadDocument}
                                isLoading={isLoading}
                                hasNoResults={hasNoResults}
                                onPreviewDoucment={onPreviewDoucment}
                            />
                        )}

                    </div>
                </div>
                <div className="my-5"></div>
            </div>

            <ProductsModal
                show={showProductsModal}
                setShow={setShowProductsModal}
                onProductClick={onProductClick}
            />

            <ProvidersModal
                show={showProvidersModal}
                setShow={setShowProvidersModal}
                onProviderClick={onProviderClick}
                currentProvider={currentProvider}
            />

            <TerritoriesModal
                show={showTerritoriesModal}
                setShow={setShowTerritoriesModal}
                markets={markets}
                territories={territories}
                currentTerritory={currentTerritory}
                onTerritoryClick={onTerritoryClick}
            />

            <PreviewModal
                show={showPreviewModal}
                setShow={setShowPreviewModal}
                onGenerateClick={null}
                isLoading={isLoading}
                pdfUrl={pdfUrl}
                showGenerateBack={false}
                showPrintDownload={true}
                pdfFileName={pdfFileName}
                supportsProfessionalPrinting={false}
            />
            <ToastAlert show={showToast} setShow={setShowToast} title={toastTitle} message={toastMessage} />
        </>
    );
}

export default QuickSheetsPage;