import agLogo from '../styles/assets/img/logo_ag_aq.svg';

export default function Splash() {
	return (
		<div className='container-xl'>
			<div className='row pt-8'>
				<div className='col-12 text-center pt-md-6'>
					<img src={agLogo} alt='Powered by Access Genius' className='mx-auto d-block page-initial-logo mb-4 mb-md-7'></img>
					<output className='fa-spinner fa-spin-pulse fa-regular type-24 type-md-36 text-secondary'>
						<span className='visually-hidden'>Loading...</span>
					</output>
				</div>
			</div>
		</div>
	);
}
